
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { Language } from '@react-lang/language';
import Footer from './Footer';
function Services() {
    const [connectWithUs, setConnectWithUs] = useState();
    const [facebook, setFacebook] = useState();
    const [tokped, setTokped] = useState();
    const [intagram, setInstagram] = useState();
    const [shopee, setShopee] = useState();
    const [linkedin, setLinkedin] = useState();
    const [headOffice, setHeadOffice] = useState();
    const [headOfficeText, setHeadOfficeText] = useState();
    const [companyProfile, setCompanyProfile] = useState();
    const [youtube, setYoutube] = useState();
    // const [ourBusiness, setOurBusiness] = useState();
    const cover = useSelector((state) => state.counter.value);
    // const subProductList = (subProduct) => {
    //     return subProduct.map( (value, index) => {
    //         return (
    //             <li key={index}>{value}</li>
    //         )
    //     });
    // }
    // const detailsList = (details) => {
    //     return details.map( (value, index) => {
    //         return (
    //             <li key={index}>
    //                 {value.name}
    //                 <ul>{subProductList(value.subProduct)}</ul>
    //             </li>
    //         )
    //     });
    // }
    // const ourBusinessHtm = ourBusinessList?.map( (value, index) => {
    //     return (
    //     )
    // });
    const handleIfrmeLoad = () => {
        let href = document.getElementsByClassName('navigate-link')[0]?.href;
        console.log(href);
        // setHeadOffice(href);
    }
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let address = payload.address;
            setFacebook(address.facebook.value);
            setTokped(address.tokped.value);
            setInstagram(address.instagram.value);
            setShopee(address.shopee.value);
            setLinkedin(address.linkedin.value);
            setHeadOffice(address.head_office.value);
            setHeadOfficeText(address.head_office.address);
            setYoutube(address.youtube.value);
            setConnectWithUs(payload.about.connect_with_us);
            setCompanyProfile(payload.background.company_profile);
        }
    }, [cover]);
    return (
        <Language.Consumer>
            {({get}) => 
                <section id="services" className="services section light-background">
                    <div className="container section-title">
                        <h2>{get('connectWithUs')}</h2>
                        {ReactHtmlParser(connectWithUs)}
                    </div>
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-4 col-md-12 d-flex aos-init aos-animate">
                                <div className="service-item position-relative">
                                    <div className="footer-brand">{get('followUs')}</div>
                                    <div className="mb-3 text-justify">{headOfficeText}</div>
                                    <div className="icon text-center">
                                        <a href={facebook} target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" className="feather feather-menu">
                                                <g><path d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h384c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z" fill="currentColor" opacity="1"></path><path d="M432 256h-80v-64c0-17.664 14.336-16 32-16h32V96h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96V336h48l32-80z" fill="#ffffff" opacity="1"></path></g>
                                            </svg>
                                        </a>
                                        <a href={tokped} target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="10 10 44 44" className="feather feather-menu">
                                                <g><g data-name="Shady Red"><path fill="#ffffff" d="M32 49a33.008 33.008 0 0 1-11.599-2H20a.988.988 0 0 1-.868-.53C14.192 44.273 11 40.852 11 37v12a4 4 0 0 0 4 4h34a4 4 0 0 0 4-4V37c0 6.627-9.402 12-21 12z" opacity="1"></path><path fill="currentColor" d="M36 47a9.01 9.01 0 0 0 9-9V24a1 1 0 0 0-1-1h-7.875a10.052 10.052 0 0 0-4.125.89 10.052 10.052 0 0 0-4.125-.89H20a1 1 0 0 0-1 1v22a.974.974 0 0 0 .132.47c.414.183.831.364 1.27.53zM21 25h6.875a8.042 8.042 0 0 1 3.578.845l.1.05a1 1 0 0 0 .895 0l.1-.05A8.042 8.042 0 0 1 36.125 25H43v13a7.008 7.008 0 0 1-7 7H21z" opacity="1"></path><path fill="#ffffff" d="M20 47h.401a24.675 24.675 0 0 1-1.269-.53A.988.988 0 0 0 20 47z" opacity="1"></path><g fill="#fb325d"><path d="M27.5 38a5.5 5.5 0 1 1 5.5-5.5 5.507 5.507 0 0 1-5.5 5.5zm0-9a3.5 3.5 0 1 0 3.5 3.5 3.504 3.504 0 0 0-3.5-3.5z" fill="currentColor" opacity="1"></path><path d="M36.5 38a5.5 5.5 0 1 1 5.5-5.5 5.507 5.507 0 0 1-5.5 5.5zm0-9a3.5 3.5 0 1 0 3.5 3.5 3.504 3.504 0 0 0-3.5-3.5z" fill="currentColor" opacity="1"></path><circle cx="36" cy="32.5" r="1" fill="currentColor" opacity="1"></circle><path d="M36 34.5a2 2 0 1 1 2-2 2.003 2.003 0 0 1-2 2z" fill="currentColor" opacity="1"></path><circle cx="28" cy="32.5" r="1" fill="currentColor" opacity="1"></circle><path d="M28 34.5a2 2 0 1 1 2-2 2.003 2.003 0 0 1-2 2zM32 41a.997.997 0 0 1-.707-.293l-2-2A1 1 0 0 1 29 38a3 3 0 0 1 6 0 1 1 0 0 1-.293.707l-2 2A.997.997 0 0 1 32 41zm-.936-3.35.936.936.936-.937a1 1 0 0 0-1.872 0zM38 24h-2v-1a4 4 0 0 0-8 0v1h-2v-1a6 6 0 0 1 12 0z" fill="currentColor" opacity="1"></path><path d="M49 54H15a5.006 5.006 0 0 1-5-5V15a5.006 5.006 0 0 1 5-5h34a5.006 5.006 0 0 1 5 5v34a5.006 5.006 0 0 1-5 5zM15 12a3.003 3.003 0 0 0-3 3v34a3.003 3.003 0 0 0 3 3h34a3.003 3.003 0 0 0 3-3V15a3.003 3.003 0 0 0-3-3z" fill="currentColor" opacity="1"></path></g></g></g>
                                            </svg>
                                        </a>
                                        <a href={linkedin} target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 510 510" className="feather feather-menu">
                                                <g><path d="M459 0H51C22.95 0 0 22.95 0 51v408c0 28.05 22.95 51 51 51h408c28.05 0 51-22.95 51-51V51c0-28.05-22.95-51-51-51zM153 433.5H76.5V204H153v229.5zm-38.25-272.85c-25.5 0-45.9-20.4-45.9-45.9s20.4-45.9 45.9-45.9 45.9 20.4 45.9 45.9-20.4 45.9-45.9 45.9zM433.5 433.5H357V298.35c0-20.399-17.85-38.25-38.25-38.25s-38.25 17.851-38.25 38.25V433.5H204V204h76.5v30.6c12.75-20.4 40.8-35.7 63.75-35.7 48.45 0 89.25 40.8 89.25 89.25V433.5z" fill="currentColor" opacity="1"></path></g>
                                            </svg>
                                        </a>
                                        <a href='https://maps.google.com/maps/dir//PT+BUMI+MANDIRI+SAKTITAMA+Jalan+Prabu+Siliwangi.KM+3+Blok+A+06+Gelam+Jaya+Pasar+Kemis,+Tangerang+Regency,+Banten+15560/@-6.1766787,106.5646345,14z/data=!4m5!4m4!1m0!1m2!1m1!1s0x2e69ff00379fcb97:0x479250e35fa3f78b' target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="5 5 54 54" className="feather feather-menu">
                                                <g><g data-name="Layer 28"><path d="M49.45 3.25h-34.9a11.31 11.31 0 0 0-11.3 11.3v34.9a11.31 11.31 0 0 0 11.3 11.3h34.9a11.31 11.31 0 0 0 11.3-11.3v-34.9a11.31 11.31 0 0 0-11.3-11.3zM32.56 50a.75.75 0 0 1-1.12 0c-.52-.58-12.84-14.4-12.84-22 0-7.83 6-14.21 13.4-14.21S45.4 20.13 45.4 28c0 7.6-12.32 21.42-12.84 22z" fill="currentColor" opacity="1"></path><circle cx="32" cy="26.73" r="5.69" fill="currentColor" opacity="1"></circle></g></g>
                                            </svg>
                                        </a>
                                        <a href={intagram} target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512" className="feather feather-menu">
                                                <g><path d="M301 256c0 24.852-20.148 45-45 45s-45-20.148-45-45 20.148-45 45-45 45 20.148 45 45zm0 0" fill="currentColor" opacity="1" data-original="currentColor" className=""></path><path d="M332 120H180c-33.086 0-60 26.914-60 60v152c0 33.086 26.914 60 60 60h152c33.086 0 60-26.914 60-60V180c0-33.086-26.914-60-60-60zm-76 211c-41.355 0-75-33.645-75-75s33.645-75 75-75 75 33.645 75 75-33.645 75-75 75zm86-146c-8.285 0-15-6.715-15-15s6.715-15 15-15 15 6.715 15 15-6.715 15-15 15zm0 0" fill="currentColor" opacity="1" data-original="currentColor" className=""></path><path d="M377 0H135C60.562 0 0 60.563 0 135v242c0 74.438 60.563 135 135 135h242c74.438 0 135-60.563 135-135V135C512 60.562 451.437 0 377 0zm45 332c0 49.625-40.375 90-90 90H180c-49.625 0-90-40.375-90-90V180c0-49.625 40.375-90 90-90h152c49.625 0 90 40.375 90 90zm0 0" fill="currentColor" opacity="1" data-original="currentColor" className=""></path></g>
                                            </svg>
                                        </a>
                                        <a href={shopee} target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 109.59 122.88" className="feather feather-menu">
                                                <g><path className="st0" d="M74.98,91.98C76.15,82.36,69.96,76.22,53.6,71c-7.92-2.7-11.66-6.24-11.57-11.12 c0.33-5.4,5.36-9.34,12.04-9.47c4.63,0.09,9.77,1.22,14.76,4.56c0.59,0.37,1.01,0.32,1.35-0.2c0.46-0.74,1.61-2.53,2-3.17 c0.26-0.42,0.31-0.96-0.35-1.44c-0.95-0.7-3.6-2.13-5.03-2.72c-3.88-1.62-8.23-2.64-12.86-2.63c-9.77,0.04-17.47,6.22-18.12,14.47 c-0.42,5.95,2.53,10.79,8.86,14.47c1.34,0.78,8.6,3.67,11.49,4.57c9.08,2.83,13.8,7.9,12.69,13.81c-1.01,5.36-6.65,8.83-14.43,8.93 c-6.17-0.24-11.71-2.75-16.02-6.1c-0.11-0.08-0.65-0.5-0.72-0.56c-0.53-0.42-1.11-0.39-1.47,0.15c-0.26,0.4-1.92,2.8-2.34,3.43 c-0.39,0.55-0.18,0.86,0.23,1.2c1.8,1.5,4.18,3.14,5.81,3.97c4.47,2.28,9.32,3.53,14.48,3.72c3.32,0.22,7.5-0.49,10.63-1.81 C70.63,102.67,74.25,97.92,74.98,91.98L74.98,91.98z M54.79,7.18c-10.59,0-19.22,9.98-19.62,22.47h39.25 C74.01,17.16,65.38,7.18,54.79,7.18L54.79,7.18z M94.99,122.88l-0.41,0l-80.82-0.01h0c-5.5-0.21-9.54-4.66-10.09-10.19l-0.05-1 l-3.61-79.5v0C0,32.12,0,32.06,0,32c0-1.28,1.03-2.33,2.3-2.35l0,0h25.48C28.41,13.15,40.26,0,54.79,0s26.39,13.15,27.01,29.65 h25.4h0.04c1.3,0,2.35,1.05,2.35,2.35c0,0.04,0,0.08,0,0.12v0l-3.96,79.81l-0.04,0.68C105.12,118.21,100.59,122.73,94.99,122.88 L94.99,122.88z" fill="currentColor"/></g>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="text-center">
                                        <a href={companyProfile} target="_blank" rel="noreferrer" class="btn btn-primary fw-500"><span>Company Profile</span></a>
                                    </div>
                                    <div class="col-lg-12 text-center">
                                        <a href={youtube} target="_blank" rel="noreferrer" className="read-more">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="224" height="224" viewBox="-25 0 512 512" className="feather feather-menu">
                                                <g><path d="M365.257 67.393H95.744C42.866 67.393 0 110.259 0 163.137v134.728c0 52.878 42.866 95.744 95.744 95.744h269.513c52.878 0 95.744-42.866 95.744-95.744V163.137c0-52.878-42.866-95.744-95.744-95.744zm-64.751 169.663-126.06 60.123c-3.359 1.602-7.239-.847-7.239-4.568V168.607c0-3.774 3.982-6.22 7.348-4.514l126.06 63.881c3.748 1.899 3.683 7.274-.109 9.082z" fill="currentColor"></path></g>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-md-12 d-flex aos-init aos-animate">
                                <div className="service-item position-relative">
                                    <iframe src={headOffice} width="100%" height="450" style={{border:0}} loading="lazy" referrerPolicy="no-referrer-when-downgrade" onLoad={handleIfrmeLoad} title={get('place')}></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </section>
            }
        </Language.Consumer>
    );
}
export default Services;