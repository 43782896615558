import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import RemoveTagHtml from '../../service/RemoveTagHtml';
import { useDispatch } from 'react-redux';
import { getData } from '../../redux/Counter';
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { Language } from '@react-lang/language';

function Career() {
    const [titleCareer, setTitleCareer] = useState();
    const [career, setCareer] = useState();
    const [careerId, setCareerId] = useState();
    const cover = useSelector((state) => state.counter.value);
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const navigateProduct = (ids, position) => {
        let path = position.replaceAll(' ', '-');
        let payload = {...cover.payload};
        payload.careerId = ids;
        dispacth(getData(payload));
        navigate(`/career/${path}`);
    };
    const divisionDetailsHtlm = (id, details) => {
        return Object.entries(details).map(([key, value], index) => {
            const position = value.position;
            const ids = [id, key];
            return (
                <>
                    <li className="list-group-item" key={index}>
                        <button onClick={() => navigateProduct(ids, position)}>{position}</button>
                        <div className="small">{value.placement}</div>
                    </li>
                </>
            );
        });
    };
    const divisionHtml = () => {
        if(careerId) {
            let details = career[careerId[0]].details[careerId[1]];
            return (
                <>
                    <h4 className="mb-4">{details.position}</h4>
                    <p className="mb-5">{ReactHtmlParser(details.value)}</p>
                </>
            )
        }
        else if(career) {
            return Object.entries(career).map(([key, value], index) => {
                const details = value.details;
                return (
                    <>
                        <div className="d-flex align-items-center justify-content-between" key={index}>
                            <h2 className="mb-0">{value.division}</h2>
                            <div className="badge bg-primary-soft text-primary badge-marketing">{details.length} Openings</div>
                        </div>
                        <hr className="mb-0" />
                        <ul className="list-group list-group-flush list-group-careers">
                            {divisionDetailsHtlm(key, details)}
                        </ul>
                    </>
                );
            });
        }
        else {
            return (
                <Language.Consumer>
                    {({get}) => 
                        <h4 className="mb-4">{get('notAvailable')}</h4>
                    }
                </Language.Consumer>
            )
        }
    };
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            setTitleCareer(RemoveTagHtml(payload.about.career));
            setCareer(payload.career);
            setCareerId(payload.careerId);
        }
    }, [cover]);
    return (
        <>
            <section className="bg-white">
                <div className="container px-5">
                    <div className="row gx-5 justify-content-center">
                        <div className="col-lg-10">
                            {divisionHtml()}
                            <hr className="my-5" />
                            <div className="card bg-light shadow-none">
                                <div className="card-body text-center p-5">
                                    <p className="lead mb-4">{titleCareer}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="svg-border-rounded text-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
            </section>
        </>
    );
}
export default Career;