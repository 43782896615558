import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Language } from '@react-lang/language';
import Footer from './Footer';
import noImage from '../../assets/img/no-image.jpg';
import { useDispatch } from 'react-redux';
import { getData } from '../../redux/Counter';
import { useNavigate } from "react-router-dom";

function Portfolio() {
    const [portofolio, setPortofolio] = useState();
    const [product, setProduct] = useState();
    const [activities, setActivities] = useState();
    const [paginationList, setPaginationList] = useState();
    const [page, setPage] = useState(0);
    const [dailyId, setDailyId] = useState();
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const cover = useSelector((state) => state.counter.value);
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const navigateDetail = (id, name) => {
            let path = name.replaceAll(' ', '-');
            let payload = {...cover.payload};
            payload.dailyId = id;
            dispacth(getData(payload));
            navigate(`/daily/${path}`);
    }
    const activitiesHtml = () => {
        console.log(activities)
        return Object.entries(activities).map(([key, value], index) => {
            let imgFluid = Object.values(value.files)[0] ?? noImage;
            let createdAt = value.createdAt;
            let name = value.name;
            return (
                <Language.Consumer>
                    {({get}) => 
                        <div className="col mb-4 portfolio-item construction interior" key={index}>
                            <div className="image-link">
                                <img src={imgFluid} className="img-fluid" alt={name}/>
                            </div>
                            <div class="post-content">
                                <div class="meta-date">{get(month[createdAt[1]-1])} {createdAt[2]}, {createdAt[0]}</div>
                                <h3 class="post-title">
                                    <button onClick={() => navigateDetail(value.id, name)}>{name}</button>
                                </h3>
                                <p>{value.description}</p>
                            </div>
                        </div>
                    }
                </Language.Consumer>
            )
        })
    };
    const pagination = () => {
        setPaginationList([]);
        let paginationListTemp = [];
        let total = activities?.length;
        let pages = Math.ceil(total / 3);
        let length = pages > 7 ? 7 : pages;
        for (let index = 0; index < length; index++) {
            let start = page - 3;
            start = start < 0 ? 0 : start;
            let end = start + 6;
            let temp = pages - 1;
            if(end >= temp) {
                if(pages > 7) start -= (end - temp);
                end = temp;
            }
            let label;
            if(index === 0) {
                label = 1;
            }
            else if(index === 6) {
                label = pages;
            }
            else {
                label = start + index + 1;
            }
            paginationListTemp.push({
                value: label - 1,
                active: page === label - 1,
                label: (index === 1 && label !== 2) || (index === 5 && label !== pages - 1) ? "..." : label
            });
        }
        setPaginationList(paginationListTemp);
    };
    const change = (val) => {
        setPage(val);
    };
    const paginationHtml = paginationList?.map((val, index) => {
        let className = "page-item";
        if(val.active) {
            className += " active";
        }
        return (
            <>
                <li className={className} key={index}>
                    <button class="page-link" onClick={() => change(val.value)}>{val.label}</button>
                </li>
            </>
        );
    });
    const getId = (url) => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url?.match(regExp);
        return (match && match[2].length === 11)
            ? match[2]
            : null;
    };
    const postPreviewHtml = (files) => {
        return Object.entries(files).map(([key, value], index) => {
            return (
                <>
                    <div class="col-md-6 col-xl-4 mb-5" key={index}>
                        <div class="card post-preview lift h-100">
                            <img src={value} class="card-img-top" alt=""/>
                        </div>
                    </div>
                </>
            );
        });
    } 
    const postPreviewFeaturedHtml = (details) => {
            let links = Object.values(details.links)?.[0];
            let embedLink = `https://youtube.com/embed/${getId(links)}?autoplay=1`;
            return (
                <>
                    <div class="post-preview post-preview-featured lift mb-5">
                        <div class="row gx-0">
                            <div class="col-lg-5">
                                <iframe className='video' title='Youtube player' sandbox='allow-same-origin allow-forms allow-popups allow-scripts allow-presentation' src={embedLink} />
                            </div>
                            <div class="col-lg-7">
                                <div class="card-body">
                                    <div class="py-5">
                                        <h5 class="card-title">{details.name}</h5>
                                        <p class="card-text">{details.description}</p>
                                    </div>
                                    {/* <hr/>
                                    <div class="post-preview-meta">
                                        <div class="post-preview-meta-details">
                                            <div class="post-preview-meta-details-name">Valerie Luna</div>
                                            <div class="post-preview-meta-details-date">Feb 5 · 6 min read</div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
    }
    const dailyHtml = () => {
        if(dailyId) {
            let details = activities[dailyId];
            return (
                <>
                    {postPreviewFeaturedHtml(details)}
                    <div class="row gx-5">
                        {postPreviewHtml(details.files)}
                    </div>
                </>
            );
        }
        else if(activities) {
            return (
                <>
                    <div className="grid p-0 clearfix row row-cols-2 row-cols-lg-3 row-cols-xl-3">
                        {activitiesHtml()}
                    </div>
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pagination-blog justify-content-center">
                            <li class="page-item disabled">
                                <a aria-label="Previous" class="page-link">
                                    <span aria-hidden="true">«</span>
                                </a>
                            </li>
                            {paginationHtml}
                            {/* <li class="page-item active">
                                <a class="page-link">1</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link">2</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link">3</a>
                            </li>
                            <li class="page-item disabled">
                                <a class="page-link">...</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link">12</a>
                            </li> */}
                            <li class="page-item disabled">
                                <a aria-label="Next" class="page-link">
                                    <span aria-hidden="true">»</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </>
            );
        }
        else {
            return (
                <>
                    <h4 className="mb-4">Belum Tersedia</h4>
                </>
            )
        }
    }
    const portofolioHtml = portofolio?.map((value, index) => {
        return (
            <div className="col mb-4 portfolio-item construction interior" key={index}>
                <div className="image-link">
                    <img src={value.fileName} className="img-fluid" alt={value.name}/>
                </div>
            </div>
        )
    });
    const productHtml = product?.map((value, index) => {
        return (
            <button className="btn btn-outline rounded-pill text-uppercase is-checked" key={index}>{value.product}</button>
        )
    });
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let portfolio = payload.portfolio;
            console.log(portfolio)
            setPortofolio(portfolio);
            let productUniq = portfolio.filter((obj, index) =>
                portfolio.findIndex((item) => item.product === obj.product) === index
            );
            productUniq.unshift({
                product: "All"
            })
            setProduct(productUniq);
            // setActivities(payload.activities);
            // setDailyId(payload.dailyId);
        }
    }, [cover]);
    useEffect(() => {
        if(page === 0) {
            pagination();
        }
        else {
            change(0);
        }
    }, [activities]);
    useEffect(() => {
        pagination();
    }, [page]);
    return (
        <Language.Consumer>
            {({get}) => 
                <section id="portfolio" className="scrollspy-section bg-dark padding-large">
                    <div className="container">
                        {/* {dailyHtml()} */}
                        <div className="row">
                            <div id="filters" className="button-group d-flex flex-wrap gap-4 py-5">
                                {productHtml}
                            </div>
                        </div>
                        <div className="grid p-0 clearfix row row-cols-2 row-cols-lg-3 row-cols-xl-3">
                            {portofolioHtml}
                        </div>
                    </div>
                    <Footer />
                </section>
            }
        </Language.Consumer>
    );
}
export default Portfolio;