import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Language } from '@react-lang/language';
import RemoveTagHtml from '../../service/RemoveTagHtml';
function OurTopClientele() {
    const [ourTopClientele, setOurTopClientele] = useState();
    const [componentOurTopClientele, setComponentOurTopClientele] = useState();
    const [client, setClient] = useState();
    const cover = useSelector((state) => state.counter.value);
    const clientHtml = client?.map( (value, index) => {
        return (
            <div className="col-lg-4 mb-5" key={index}>
                <h6 className="mb-3">{value.name}</h6>
                <div className="list-group small shadow mb-5">
                    <div className="list-group-item list-group-item-action d-flex align-items-center justify-content-between">
                        <div className="ng-fa-icon fa-xs text-gray-400 icon">
                            <img src={value.fileName} className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    });
    const getComponentOurTopClientele = (clientLength) => {
        console.log(clientLength)
        return clientLength > 0 ? (
            <Language.Consumer>
                {({get}) => 
                    <section className="our-top-clientele py-10 bg-white"  id="our-top-clientele">
                        <div className="container px-5">
                            <div className="row gx-5 justify-content-center">
                                <div className="col-lg-8">
                                    <div className="text-center mb-10">
                                        <div className="badge rounded-pill bg-primary-soft text-primary badge-marketing mb-3">{client?.length} {get('client')}!</div>
                                        <h2>{get('ourTopClientele')}</h2>
                                        <p className="lead">{RemoveTagHtml(ourTopClientele)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row gx-5">
                                {clientHtml}
                            </div>
                        </div>
                        <div className="svg-border-rounded">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path _ngcontent-epe-c35="" d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                        </div>
                    </section>
                }
            </Language.Consumer>
        ) : (<></>);
    }
    useEffect(() => {
        let payload = cover?.payload;
        console.log(payload)
        if(payload) {
            let client = payload.client
            setClient(client);
            setOurTopClientele(payload.about.our_top_clientele);
            console.log(client)
            setComponentOurTopClientele(getComponentOurTopClientele(client.length))
        }
    }, [cover]);
    return (
        <>
            {componentOurTopClientele}
        </>
    );
}
export default OurTopClientele;