let counting = 0;
const ServiceRequest = (service, callback) => {
    service.then( response => callback(response.data)).catch(
        e => {
            if(counting < 3) {
                counting++;
                console.log(`Trying to access server (${counting})`);
                ServiceRequest(service, callback);
            }
        }
    )
};
export default ServiceRequest;