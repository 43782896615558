import { useState, useEffect } from 'react';
import Footer from './Footer';
import { Language } from '@react-lang/language';
import ServiceRequest from '../../service/ServiceRequest';
import InboxService from '../../service/InboxService';
import { useSelector } from 'react-redux';
import RemoveTagHtml from '../../service/RemoveTagHtml';

function LayoutContactUs() {
    const [valueInputName, setValueInputName] = useState('');
    const [valueInputEmail, setValueInputEmail] = useState('');
    const [valueInputMessage, setValueInputMessage] = useState('');
    const [responseSubmitFormContactNow, setResponseSubmitFormContactNow] = useState('');
    const cover = useSelector((state) => state.counter.value);
    const handleSubmit = (e) => {
        const value = {
            inputName: valueInputName,
            inputEmail: valueInputEmail,
            inputMessage: valueInputMessage
        };
        ServiceRequest(InboxService(value), requestCallback);
        e.preventDefault();
    };
    const requestCallback = (response) => {
        console.log(response);
        alert(RemoveTagHtml(responseSubmitFormContactNow));
        setValueInputName('');
        setValueInputEmail('');
        setValueInputMessage('');
    }
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            setResponseSubmitFormContactNow(payload.about.response_submit_form_contact_now);
        }
    }, [cover]);
    useEffect(() => {
        let element = document.getElementById('contact-us');
        element.scrollIntoView({ behavior: 'smooth' });
    }, []);
    return (
        <Language.Consumer>
            {({get}) => 
                <section className="py-10 bg-light">
                    <div className="container px-5" id="contact-us">
                        <div className="row gx-5 justify-content-center">
                            <div className="col-lg-8 text-center">
                                <h2>{get('cantFindThe')}</h2>
                                <p className="lead mb-5">{get('contactUsNowDescription')}</p>
                            </div>
                        </div>
                        <div className="row gx-5 align-items-center mb-10">
                            <div className="col-lg-4 text-center mb-5 mb-lg-0">
                                <div className="section-preheading">{get('messageUs')}</div>
                                <a>{get('startAchat')}</a>
                            </div>
                            <div className="col-lg-4 text-center mb-5 mb-lg-0">
                                <div className="section-preheading">{get('callAnytime')}</div>
                                <a>(021) 3896-2028</a>
                            </div>
                            <div className="col-lg-4 text-center">
                                <div className="section-preheading">{get('emailUs')}</div>
                                <div><a>cs@bems.co.id</a></div>
                                <div><a>purchasing@bems.co.id</a></div>
                            </div>
                        </div>
                        <form className="ng-untouched ng-pristine ng-valid" onSubmit={(event) => handleSubmit(event)}>
                            <div className="row gx-5">
                                <div className="mb-4 col-md-6">
                                    <label htmlFor="inputName" className="mb-2 text-dark">{get('fullName')}</label>
                                    <input id="inputName" type="text" placeholder={get('fullName')} className="form-control py-4" value={valueInputName} onInput={(e) => setValueInputName(e.target.value)} />
                                </div>
                                <div className="mb-4 col-md-6">
                                    <label htmlFor="inputEmail" className="mb-2 text-dark">Email</label>
                                    <input id="inputEmail" type="email" placeholder={get('name') + "@example.com"} className="form-control py-4" value={valueInputEmail} onInput={(e) => setValueInputEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="mb-4">
                                <label htmlFor="inputMessage" className="mb-2 text-dark">{get('message')}</label>
                                <textarea id="inputMessage" type="text" placeholder={get('enterYourMessage')} rows="4" className="form-control py-3" value={valueInputMessage} onInput={(e) => setValueInputMessage(e.target.value)} />
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-primary fw-500 mt-4">{get('submitYourQuestion')}</button>
                            </div>
                        </form>
                    </div>
                    <Footer />
                </section>
            }
        </Language.Consumer>
    );
}
export default LayoutContactUs;