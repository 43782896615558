import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from '../App';
import Main from '../page/component/Main';
import ContactUsPage from '../page/view/ContactUsPage';
import Home from '../page/view/Home';
import Product from '../page/component/Product';
import Career from '../page/component/Career';
import Portfolio from '../page/component/Portfolio';
import Portofolio from '../page/component/Portofolio1';

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}>
                    <Route index element={<Home />} />
                    <Route path="contact-us" element={<ContactUsPage />} />
                    <Route path="product" element={<Product />} />
                    <Route path="product/:detail" element={<Product />} />
                    <Route path="career" element={<Career />} />
                    <Route path="daily" element={<Portfolio />} />
                    {/* <Route path="demo" element={<Main/>}  > */}
                        {/* <Route index element={<Home />} /> */}
                        {/* <Route path=":contact-us" element={<ContactUsPage/>} /> */}
                        {/* <Route path=":product" />
                        <Route path=":product/:detail" />
                    </Route> */}
                    {/* <Route path="*" element={<NotFoundView />} /> */}
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
export default Router;