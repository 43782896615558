import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { Language } from '@react-lang/language';
function Clients() {
    const [jarallaxImg, setJarallaxImg] = useState();
    const [aboutUs, setAboutUs] = useState();
    const [visi, setVisi] = useState();
    const [misi, setMisi] = useState();
    const cover = useSelector((state) => state.counter.value);
    // const [scrollPosition, setScrollPosition] = useState();
    // const [jarallaxImgStyle, setJarallaxImgStyle] = useState();
    // const handleScroll = () => {
    //     const boundingClientRect = document.getElementById('jarallax-container-3').getBoundingClientRect();
    //     const jarallaxCrollWidth = document.getElementById('jarallax-container-3').scrollHeight;
    //     const scrollTop = document.documentElement.scrollTop;
    //     const scrollHeight = document.documentElement.scrollHeight;
    //     const clientHeight = document.documentElement.clientHeight;
    //     const basePosition = jarallaxCrollWidth * 0.497;
    //     const height = boundingClientRect.height;
    //     const maxTop = height / 2;
    //     const top = boundingClientRect.top;
    //     if(maxTop > top) {
    //         const position = Math.ceil(
    //             (scrollTop / (scrollHeight - clientHeight))*100
    //         )-maxTop;
    //         setScrollPosition(basePosition + position);
    //     }
    //     setJarallaxImgStyle({
    //         width: boundingClientRect.width,
    //         height: height,
    //         left: boundingClientRect.left,
    //         // "margin-top": scrollHeight * -0.0005,
    //         transform: 'translate3d(0px, ' + scrollPosition +'px, 0px)'
    //     });
    // };
    // useEffect(() => {
    //     handleScroll();
    //     window.addEventListener('scroll', handleScroll);
    
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let about = payload.about;
            setJarallaxImg(payload.background.about);
            setAboutUs(about.about_company);
            setVisi(about.vision);
            setMisi(about.mission);
        }
    }, [cover]);
    return (
        <>
            <section id="clients" className="clients section-bg section dashboard">
                <Language.Consumer>
                    {({get}) => 
                        <div className="container row">
                            <div className="row col-lg-12">
                                <div className="col-md-12">
                                    <div className="section-header">
                                        <div className="title">
                                            <span>{get('whoAreWe')}</span>
                                        </div>
                                        <h2 className="section-title">{get('about')}</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-lg-12">
                                <div className="col-md-6">
                                    <figure className="jarallax-keep-img">
                                        <img src={jarallaxImg} alt="about us" className="jarallax-img single-image"></img>
                                    </figure>
                                </div>
                                <div className="col-md-6 description text-lead">
                                    {ReactHtmlParser(aboutUs)}
                                    <div className="visi-misi-wrap" id="visi">
                                        <h3>{get('vision')}</h3>
                                        {ReactHtmlParser(visi)}
                                    </div>
                                    <div className="visi-misi-wrap" id="misi">
                                        <h3>{get('mision')}</h3>
                                        {ReactHtmlParser(misi)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </Language.Consumer>
            </section>
        </>
    );
}
export default Clients;