import axios from "axios";

class HttpCommon {
    getHeaders() {
        let headers = {
            headers: {
                "Content-type": "application/json"
            }
        };
        return headers; 
    }
    requestGet(path) {
        var http = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL
        });
        return http.get(path)
    }
    requestPost(data, path) {
        return axios.post(process.env.REACT_APP_BASE_URL + path, data, this.getHeaders());
    }
}

export default new HttpCommon();