
import Footer from './Footer';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getData } from '../../redux/Counter';
import { useNavigate } from "react-router-dom";
function Product() {
    const cover = useSelector((state) => state.counter.value);
    const [ourProductList, setOurProductList] = useState();
    const [ourProductSelected, setOurProductSelected] = useState();
    const dispacth = useDispatch();
    const navigate = useNavigate();
    const faxContent = (details, ids) => {
        return details ? (
            <div class="faq-content">
                {ourProductHtml(details, ids)}
            </div>
        ) : '';
    }
    const ourProductHtml = (details, ids) => {
        return details?.map((value) => {
            let name = value.name;
            let id = value.id;
            return (
                <div id={`product${id}`} class="faq-item">
                    <h3><button onClick={() => handleProduct(id, name, ids)}>{name}</button></h3>
                    {faxContent(value.details, [...ids, id])}
                    {/* <div className="ng-fa-icon dropdown-arrow">
                        <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                        </svg>
                    </div> */}
                </div>
            )
        });
    }
    const handleProduct = (id, name, ids) => {
        let element = document.getElementById(`product${id}`);
        element?.classList.toggle('faq-active');
        let path = name.replaceAll(' ', '-');
        let payload = {...cover.payload};
        payload.product = ids ? [...ids, id] : [id];
        dispacth(getData(payload));
        navigate(`/product/${path}`);
    }
    useEffect(() => {
        let payload = cover?.payload;
        if(payload) {
            let ourBusinessList = payload.ourBusinessList;
            payload.product?.forEach((value, index) => {
                let ourBusiness = ourBusinessList.find((value1) => value1.id == value);
                ourBusinessList = ourBusiness.details;
                setOurProductSelected(ourBusiness);

                if(index === 0) {
                    setOurProductList(ourBusiness);
                }
            });
        }
    }, [cover]);
    return (
        <>
            <section className="py-10 bg-white">
                <div className="container px-5 mt-10">
                    <div className="row gx-5">
                        <div className="col-lg-3">
                            <div class="faq-item faq-active">
                                <h3><button onClick={() => handleProduct(ourProductList?.id, ourProductList?.name)}>{ourProductList?.name}</button></h3>
                                <div class="faq-content">
                                    {ourProductHtml(ourProductList?.details, [ourProductList?.id])}
                                </div>
                                {/* <div className="ng-fa-icon dropdown-arrow">
                                    <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                        <path fill="currentColor" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"></path>
                                    </svg>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <img src={ourProductSelected?.fileName} alt={ourProductSelected?.name} class="card-img-top"></img>
                            <h1 className="mb-4">{ourProductSelected?.name}</h1>
                            <p className="lead mb-4">{ourProductSelected?.description}</p>
                            {/* <div className="card bg-light shadow-none">
                                <div className="card-body d-flex align-items-center justify-content-between p-4">
                                    <p className="lead mb-0">We look forward to hearing from you</p>
                                    <a className="btn btn-primary fw-500 ms-2">Apply Now</a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="svg-border-rounded text-dark">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
                </div>
                <Footer />
            </section>
        </>
    );
}
export default Product;